<template>
  <div class="not-found">
    <h1>(404) Page NotFound</h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.not-found {
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 800px;
  align-content: space-between;
  flex-direction: column;
}
</style>